.App {
  text-align: center;
}
.noBar::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome browsers */
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* 
  Font: "Neon Glow" by weknow 
  (https://www.fontspace.com/neon-glow-font-f14014) 
*/
@font-face {
  font-family: 'Neon Glow';
  src: url(https://assets.codepen.io/230569/NeonGlow-8VLz.ttf);
}




#gasses {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  
  > * {
    margin: 2rem;
  }
}

.gas {
  --blur: 1.75rem;
  --glow: var(--color);
  --size: 12rem;
  
  align-items: center;
  border-radius: 12px;
  box-shadow: 
    /* --- "glass" tube --- */
    /* inside */ inset 0 0 0 2px rgba(0, 0, 0, 0.15),
    /* outside */      0 0 0 2px rgba(0, 0, 0, 0.15),
    /* --- glow --- */
    /* inside */ inset 0 0 var(--box-blur) var(--glow),
    /* outside */      0 0 var(--box-blur) var(--glow);
  color: var(--color, white);
  display: inline-flex;
  font-family: system-ui, sans-serif;
  
  > * {
    margin: 0;
    padding: 0;
  }
  
  .number { 
    font-weight: 700; 
  }
  
  .symbol { 
    font-size: 14rem; 
    font-weight: 700;
    text-shadow: 0 0 var(--blur) var(--glow);
  }

  .symbol2 { 
    font-size: 1rem; 
    font-weight: 700;
    text-shadow: 0 0 var(--blur) var(--glow);
  }
  
  &.he { --color: #FCEAAC; filter: saturate(175%); }
  &.ne { --color: #FFE037; filter: brightness(100%); }
  &.ar { --color: #E555C7; filter: brightness(125%); }
  &.kr { --color: #B7E7F7; filter: saturate(200%); }
  &.xe { --color: #C4C4C6; filter: brightness(105%); }
}


.text-flicker-in-glow1 {
	-webkit-animation: text-flicker-in-glow 4s linear both;
	        animation: text-flicker-in-glow 4s linear both;
}
.text-flicker-in-glow2 {
	-webkit-animation: text-flicker-in-glow 2S linear both;
	        animation: text-flicker-in-glow 2s linear both;
}

.text-flicker-in-glow3 {
	-webkit-animation: text-flicker-in-glow 5s linear both;
	        animation: text-flicker-in-glow 5s linear both;
}


@-webkit-keyframes text-flicker-in-glow {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 0;
    text-shadow: none;
  }
  10.1% {
    opacity: 1;
    text-shadow: none;
  }
  10.2% {
    opacity: 0;
    text-shadow: none;
  }
  20% {
    opacity: 0;
    text-shadow: none;
  }
  20.1% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 224, 55, 0.25);
  }
  20.6% {
    opacity: 0;
    text-shadow: none;
  }
  30% {
    opacity: 0;
    text-shadow: none;
  }
  30.1% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 224, 55, 0.45), 0 0 60px rgba(255, 224, 55, 0.25);
  }
  30.5% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 224, 55, 0.45), 0 0 60px rgba(255, 224, 55, 0.25);
  }
  30.6% {
    opacity: 0;
    text-shadow: none;
  }
  45% {
    opacity: 0;
    text-shadow: none;
  }
  45.1% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 224, 55, 0.45), 0 0 60px rgba(255, 224, 55, 0.25);
  }
  50% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 224, 55, 0.45), 0 0 60px rgba(255, 224, 55, 0.25);
  }
  55% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 224, 55, 0.45), 0 0 60px rgba(255, 224, 55, 0.25);
  }
  55.1% {
    opacity: 0;
    text-shadow: none;
  }
  57% {
    opacity: 0;
    text-shadow: none;
  }
  57.1% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 224, 55, 0.55), 0 0 60px rgba(255, 224, 55, 0.35);
  }
  60% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 224, 55, 0.55), 0 0 60px rgba(255, 224, 55, 0.35);
  }
  60.1% {
    opacity: 0;
    text-shadow: none;
  }
  65% {
    opacity: 0;
    text-shadow: none;
  }
  65.1% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 224, 55, 0.55), 0 0 60px rgba(255, 224, 55, 0.35), 0 0 100px rgba(255, 224, 55, 0.1);
  }
  75% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 224, 55, 0.55), 0 0 60px rgba(255, 224, 55, 0.35), 0 0 100px rgba(255, 224, 55, 0.1);
  }
  75.1% {
    opacity: 0;
    text-shadow: none;
  }
  77% {
    opacity: 0;
    text-shadow: none;
  }
  77.1% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 224, 55, 0.55), 0 0 60px rgba(255, 224, 55, 0.4), 0 0 110px rgba(255, 224, 55, 0.2), 0 0 100px rgba(255, 224, 55, 0.1);
  }
  85% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 224, 55, 0.55), 0 0 60px rgba(255, 224, 55, 0.4), 0 0 110px rgba(255, 224, 55, 0.2), 0 0 100px rgba(255, 224, 55, 0.1);
  }
  85.1% {
    opacity: 0;
    text-shadow: none;
  }
  86% {
    opacity: 0;
    text-shadow: none;
  }
  86.1% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 224, 55, 0.6), 0 0 60px rgba(255, 224, 55, 0.45), 0 0 110px rgba(255, 224, 55, 0.25), 0 0 100px rgba(255, 224, 55, 0.1);
  }
  100% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 224, 55, 0.6), 0 0 60px rgba(255, 224, 55, 0.45), 0 0 110px rgba(255, 224, 55, 0.25), 0 0 100px rgba(255, 224, 55, 0.1);
  }
}
@keyframes text-flicker-in-glow {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 0;
    text-shadow: none;
  }
  10.1% {
    opacity: 1;
    text-shadow: none;
  }
  10.2% {
    opacity: 0;
    text-shadow: none;
  }
  20% {
    opacity: 0;
    text-shadow: none;
  }
  20.1% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 224, 55, 0.25);
  }
  20.6% {
    opacity: 0;
    text-shadow: none;
  }
  30% {
    opacity: 0;
    text-shadow: none;
  }
  30.1% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 224, 55, 0.45), 0 0 60px rgba(255, 224, 55, 0.25);
  }
  30.5% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 224, 55, 0.45), 0 0 60px rgba(255, 224, 55, 0.25);
  }
  30.6% {
    opacity: 0;
    text-shadow: none;
  }
  45% {
    opacity: 0;
    text-shadow: none;
  }
  45.1% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 224, 55, 0.45), 0 0 60px rgba(255, 224, 55, 0.25);
  }
  50% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 224, 55, 0.45), 0 0 60px rgba(255, 224, 55, 0.25);
  }
  55% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 224, 55, 0.45), 0 0 60px rgba(255, 224, 55, 0.25);
  }
  55.1% {
    opacity: 0;
    text-shadow: none;
  }
  57% {
    opacity: 0;
    text-shadow: none;
  }
  57.1% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 224, 55, 0.55), 0 0 60px rgba(255, 224, 55, 0.35);
  }
  60% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 224, 55, 0.55), 0 0 60px rgba(255, 224, 55, 0.35);
  }
  60.1% {
    opacity: 0;
    text-shadow: none;
  }
  65% {
    opacity: 0;
    text-shadow: none;
  }
  65.1% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 224, 55, 0.55), 0 0 60px rgba(255, 224, 55, 0.35), 0 0 100px rgba(255, 224, 55, 0.1);
  }
  75% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 224, 55, 0.55), 0 0 60px rgba(255, 224, 55, 0.35), 0 0 100px rgba(255, 224, 55, 0.1);
  }
  75.1% {
    opacity: 0;
    text-shadow: none;
  }
  77% {
    opacity: 0;
    text-shadow: none;
  }
  77.1% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 224, 55, 0.55), 0 0 60px rgba(255, 224, 55, 0.4), 0 0 110px rgba(255, 224, 55, 0.2), 0 0 100px rgba(255, 224, 55, 0.1);
  }
  85% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 224, 55, 0.55), 0 0 60px rgba(255, 224, 55, 0.4), 0 0 110px rgba(255, 224, 55, 0.2), 0 0 100px rgba(255, 224, 55, 0.1);
  }
  85.1% {
    opacity: 0;
    text-shadow: none;
  }
  86% {
    opacity: 0;
    text-shadow: none;
  }
  86.1% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 224, 55, 0.6), 0 0 60px rgba(255, 224, 55, 0.45), 0 0 110px rgba(255, 224, 55, 0.25), 0 0 100px rgba(255, 224, 55, 0.1);
  }
  100% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 224, 55, 0.6), 0 0 60px rgba(255, 224, 55, 0.45), 0 0 110px rgba(255, 224, 55, 0.25), 0 0 100px rgba(255, 224, 55, 0.1);
  }
}

.focus-in-contract {
	-webkit-animation: focus-in-contract 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) 5s both;
	        animation: focus-in-contract 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940)5s both;
}

@-webkit-keyframes focus-in-contract {
  0% {
    letter-spacing: 1em;
    -webkit-filter: blur(12px);
            filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1;
  }
}
@keyframes focus-in-contract {
  0% {
    letter-spacing: 1em;
    -webkit-filter: blur(12px);
            filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1;
  }
}